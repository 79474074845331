.c-Amsterdam {
  background-color: orangered;
  // display: flex;
  min-height: 100vh;

  &__text {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }

  &__image {
    max-width: 80%;
  }

  &__weather {
    margin: 20px 0;
  }
}
