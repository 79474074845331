.c-ProjectInfo {
  border-top: 1px solid grey;

  &__title-container {
    padding: 5px;
    text-decoration: underline;
    width: 100%;
  }

  &__technologies-section {
    margin: 10px;
  }

  &__technologies-container {
    display: inline-flex;
  }

  &__technology {
    margin: 5px;
  }
}
