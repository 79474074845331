.c-EmploymentCard {
  // border: 1px solid gray;
  border-width: 5px;
  border-style: solid;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 gray;
  // display: flex;
  margin: 15px;

  &__icon {
    background-color: #fff;
  }

  &__company-link {
    :link,
    :visited,
    :active,
    :hover {
      text-decoration: none;
    }
  }

  &__company-name {
    border-bottom: 1px solid black;
    color: white;
    font-size: 18px;
    font-weight: 600;
    justify-content: flex-start;
    width: 100%;
  }

  &__sub-header {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    font-size: 14px;
    margin: 10px;
  }

  &__employment-period {
    font-size: 14px;
    font-style: italic;
    font-weight: 200;
    margin: 10px;
  }

  &__project-title {
    font-weight: 600;
    padding: 5px;
  }
}
