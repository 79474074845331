.c-Logout {
  margin: auto;
  width: 80%;

  &__container {
    display: flex;
    flex-direction: column;
    padding: 14.5% 11.5% 6.5%;
  }

  &__text {
    font-size: 16px;
    margin: auto;
    padding: 20% 0;
    text-align: center;
  }

  &__button-container {
    padding: 7.5% 5.6% 0;
  }
}
