.c-TechnologyItem {
  display: inline-flex;
  justify-content: center;
  margin: auto;
  width: 10%;

  &__icon-container {
    height: 32px;
    width: 32px;
  }

  &__icon {
    height: 32px;
    width: 100%;
  }
}
