.c-Home {
  background-color: rebeccapurple;
  display: flex;
  min-height: 100vh;

  &__content_container {
    height: 100%;
    margin: auto;
  }

  &__image {
    border-radius: 50%;
  }

  &__name {
    font-size: 24px;
    color: #eee;
  }

  &__contact_details_container {
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
  }

  &__email_icon {
  }

  &__linkedin_icon {
  }
}
