

.c-App-header {
  background-color: seagreen;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;

  &__login {
  }

  &__login_link {
    color: white;
    font-size: 16px;
    text-decoration: none;
  }

  &__login_button {
    cursor: pointer;
  }

  &__logout {
  }

  &__logout_link {
    color: white;
    font-size: 16px;
    text-decoration: none;
  }

  &__logout_button {
    cursor: pointer;
  }
}
