.c-Login {
  &__title {
    font-size: 24px;
  }

  &__field_label {
    margin: 20px auto;
    text-align: left;
    width: 25%;
  }

  &__input {
    border: 1px solid grey;
    border-radius: 2px;
    margin: 4px 0;
    padding: 6px;
    width: 100%;
  }
}
