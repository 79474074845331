.c-AnaCountdown {
  background-color: #800000;
  min-height: 100vh;

  &__text {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }

  &__image {
    height: 80%;
    margin: 50px 0;
    max-height: 400px;
    max-width: 450px;
    transform: rotate(90deg);
    width: 60%;
  }
}
